// Source:
// Countries with long/lat => https://developers.google.com/public-data/docs/canonical/countries_csv
// Countries images => https://github.com/djaiss/mapsicon

export const countryCodesWithImage = [
  "municip5046",
  "municip5045",
  "municip1103",
  "municip1106",
  "municip5042",
  "municip5041", //scream
  "municip5047", //earring
  "municip5048", //botticelli
  "municip5049",
  "municip5050",
  "municip5051",
  "municip5052",
  "municip5053",
  "municip5054",
  "municip5055",
  "municip5056",
  "municip5057",
  "municip5058",
  "municip5059",
  "municip5060",
  "municip5061",
  "municip5062",
  "municip5063",
  "municip5064",
  "municip5065",
  "municip5066",
  "municip5067",
  "municip5068",
  "municip5069",
  "municip5070",
  "municip5071",
  "municip5072",
  "municip5073",
  "municip5074",
  "municip5075",
  "municip5076",
  "municip5077",
  "municip5078",
  "municip5079",
  "municip5080",
  "municip5081",
  "municip5082",
  "municip5083",
  "municip5084",
  "municip5085",
  "municip5086",
  "municip5087",
  "municip5088",
  "municip5089",
  "municip5090",
  "municip5091",
  "municip5092",
  "municip5093",
  "municip5094",
  "municip5095",
  "municip5096",
  "municip5097",
  "municip5098",
  "municip5099",
  "municip5100",
  "municip5101",
  "municip5102",
  "municip5103",
  "municip5104",
  "municip5105",
  "municip5106",
  "municip5107",
  "municip5108",
  "municip5109",
  "municip5110",
  "municip5111",
  "municip5112",
  "municip5113",
  "municip5114",
  "municip5115",
  "municip5116",
  "municip5117",
  "municip5118",
  "municip5119",
  "municip5120",
  "municip5121",
  "municip5122",
  "municip5123",
  "municip5124",
  "municip5125",
  "municip5126",
  "municip5127",
  "municip5128",
];

export interface Country {
  country: any;
  code: string;
  latitude: number;
  longitude: number;
  name: string;
  museum: string;
  location: string;
  artist: string;
  year: number;
  attributes: string[];
}

export const countries = [
  {
    code: "municip5046",
    latitude: 48.860294,
    longitude: 2.338629,
    year: 1503,
    name: "Mona Lisa",
    artist: "Leonardo da Vinci",
    location: "Paris",
    country: "France",
    museum: "Musée du Louvre",
    attributes: ["Renaissance", "High Renaissance"],
  },
  {
    code: "municip5045",
    latitude: 45.464664,
    longitude: 9.18854,
    year: 1495,
    name: "The Last Supper",
    artist: "Leonardo da Vinci",
    location: "Milan",
    country: "Italy",
    museum: "Santa Maria delle Grazie",
    attributes: ["Renaissance", "Mural Painting"],
  },
  {
    code: "municip1103",
    latitude: 40.761509,
    longitude: -73.978271,
    year: 1889,
    name: "The Starry Night",
    artist: "Vincent van Gogh",
    location: "New York City",
    country: "Netherlands",
    museum: "Museum of Modern Art",
    attributes: ["Post-Impressionism", "Modernism"],
  },
  {
    code: "municip1106",
    latitude: 48.210033,
    longitude: 16.363449,
    year: 1907,
    name: "The Kiss",
    artist: "Gustav Klimt",
    location: "Vienna",
    country: "Austria",
    museum: "Österreichische Galerie Belvedere",
    attributes: ["Golden Period", "Modernism"],
  },
  /* {
    code: "municip5042",
    latitude: 40.416775,
    longitude: -3.7037,
    year: 1533,
    name: "The Ambassadors",
    artist: "Hans Holbein the Younger",
    location: "Madrid",
    country: "Spain",
    museum: "Museo Reina Sofía",
    attributes: ["Northern Renaissance", "Mannerism"],
  }, */
  {
    code: "municip5042",
    latitude: 40.416775,
    longitude: -3.7037,
    year: 1937,
    name: "Guernica",
    artist: "Pablo Picasso",
    location: "Madrid",
    country: "Spain",
    museum: "Museo Reina Sofía",
    attributes: ["Cubism", "Surrealism"],
  },
  {
    code: "municip5041",
    latitude: 59.911491,
    longitude: 10.757933,
    year: 1893,
    name: "The Scream",
    artist: "Edvard Munch",
    location: "Oslo",
    country: "Norway",
    museum: "Munch Museum",
    attributes: ["Expressionism", "Post-Impressionism"],
  },
  {
    code: "municip5047",
    latitude: 52.078663,
    longitude: 4.288788,
    year: 1665,
    name: "Girl with a Pearl Earring",
    artist: "Johannes Vermeer",
    location: "The Hague",
    country: "Netherlands",
    museum: "Mauritshuis",
    attributes: ["Baroque", "Dutch Golden Age"],
  },
  {
    code: "municip5048",
    latitude: 43.7678,
    longitude: 11.2559,
    year: 1486,
    name: "The Birth of Venus",
    artist: "Sandro Botticelli",
    location: "Florence",
    country: "Italy",
    museum: "Uffizi Gallery",
    attributes: ["Early Renaissance", "Italian Renaissance"],
  },
  {
    code: "municip5049",
    latitude: 40.4138,
    longitude: -3.6928,
    year: 1656,
    name: "Las Meninas",
    artist: "Diego Velázquez",
    location: "Madrid",
    country: "Spain",
    museum: "Museo del Prado",
    attributes: ["Baroque", "Spanish Golden Age"],
  },
  {
    code: "municip5050",
    latitude: 41.9029,
    longitude: 12.4534,
    year: 1508,
    name: "Creation of Adam",
    artist: "Michelangelo",
    location: "Vatican City",
    country: "Italy",
    museum: "Sistine Chapel",
    attributes: ["High Renaissance", "Italian Renaissance"],
  },
  {
    code: "municip5051",
    longitude: 4.8854,
    latitude: 52.3702,
    year: 1642,
    name: "The Night Watch",
    artist: "Rembrandt",
    location: "Amsterdam",
    country: "Netherlands",
    museum: "Rijksmuseum",
    attributes: ["Baroque", "Dutch Golden Age"],
  },
  {
    code: "municip5052",
    longitude: -73.9776,
    latitude: 40.7615,
    year: 1931,
    name: "The Persistence of Memory",
    artist: "Salvador Dalí",
    location: "New York City",
    country: "Spain",
    museum: "Museum of Modern Art",
    attributes: ["Surrealism", "Modernism"],
  },
  {
    code: "municip5053",
    longitude: 2.3206,
    latitude: 48.861,
    year: 1904,
    name: "The Thinker",
    artist: "Auguste Rodin",
    location: "Paris",
    country: "France",
    museum: "Musée Rodin",
    attributes: ["Impressionism", "Modernism"],
  },
  {
    code: "municip5054",
    longitude: -73.9776,
    latitude: 40.7615,
    year: 1962,
    name: "Campbell's Soup Cans",
    artist: "Andy Warhol",
    location: "New York City",
    country: "United States",
    museum: "Museum of Modern Art",
    attributes: ["Pop Art", "Modernism"],
  },
  {
    code: "municip5055",
    longitude: -99.1332,
    latitude: 19.4326,
    year: 1940,
    name: "Self-Portrait with Thorn Necklace and Hummingbird",
    artist: "Frida Kahlo",
    location: "Mexico City",
    country: "Mexico",
    museum: "Museo Dolores Olmedo",
    attributes: ["Surrealism", "Feminist Art"],
  },
  {
    code: "municip5056",
    longitude: 139.6917,
    latitude: 35.6895,
    year: 1830,
    name: "The Great Wave off Kanagawa",
    artist: "Hokusai",
    location: "Tokyo",
    country: "Japan",
    museum: "Tokyo National Museum",
    attributes: ["Ukiyo-e", "Edo Period"],
  },
  {
    code: "municip5057",
    longitude: -73.9776,
    latitude: 40.7615,
    year: 1909,
    name: "The Dance",
    artist: "Henri Matisse",
    location: "New York City",
    country: "France",
    museum: "Museum of Modern Art",
    attributes: ["Fauvism", "Modernism"],
  },
  {
    code: "municip5058",
    longitude: -118.2437,
    latitude: 34.0522,
    year: 1929,
    name: "The Treachery of Images",
    artist: "René Magritte",
    location: "Los Angeles",
    country: "Belgium",
    museum: "Los Angeles County Museum of Art",
    attributes: ["Surrealism", "Modernism"],
  },
  {
    code: "municip5059",
    longitude: -73.9776,
    latitude: 40.7615,
    year: 1911,
    name: "I and the Village",
    artist: "Marc Chagall",
    location: "New York City",
    country: "Belarus",
    museum: "Museum of Modern Art",
    attributes: ["Cubism", "Modernism"],
  },
  {
    code: "municip5060",
    name: "The School of Athens",
    artist: "Raphael",
    location: "Vatican City",
    country: "Italy",
    museum: "Apostolic Palace",
    year: 1511,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["High Renaissance", "Italian Renaissance"],
  },
  {
    code: "municip5061",
    name: "Pietà",
    artist: "Michelangelo",
    location: "Vatican City",
    country: "Italy",
    museum: "St. Peter's Basilica",
    year: 1499,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["High Renaissance", "Italian Renaissance"],
  },
  {
    code: "municip5062",
    name: "Yellow-Red-Blue",
    artist: "Wassily Kandinsky",
    location: "Paris",
    country: "Russia",
    museum: "Musee National d'Art Moderne",
    year: 1925,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Abstract Art", "Modernism"],
  },
  {
    code: "municip5063",
    name: "The Third of May 1808",
    artist: "Francisco Goya",
    location: "Madrid",
    country: "Spain",
    museum: "Museo del Prado",
    year: 1814,
    latitude: 40.416775,
    longitude: -3.7037,
    attributes: ["Romanticism", "Spanish Golden Age"],
  },
  {
    code: "municip5064",
    name: "Narcissus",
    artist: "Caravaggio",
    location: "Rome",
    country: "Italy",
    museum: "Galleria Nazionale d'Arte Antica",
    year: 1597,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["Baroque", "Italian Renaissance"],
  },
  {
    code: "municip5065",
    name: "The Milkmaid",
    artist: "Johannes Vermeer",
    location: "Amsterdam",
    country: "Netherlands",
    museum: "Rijksmuseum",
    year: 1658,
    latitude: 52.078663,
    longitude: 4.288788,
    attributes: ["Baroque", "Dutch Golden Age"],
  },
  {
    code: "municip5066",
    name: "The Fighting Temeraire",
    artist: "J. M. W. Turner",
    location: "London",
    country: "United Kingdom",
    museum: "National Gallery",
    year: 1839,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Romanticism", "Modernism"],
  },
  {
    code: "municip5067",
    name: "The Death of Marat",
    artist: "Jacques-Louis David",
    location: "Brussels",
    country: "France",
    museum: "Royal Museums of Fine Arts of Belgium",
    year: 1793,
    latitude: 50.850346,
    longitude: 4.351721,
    attributes: ["Neoclassicism", "French Revolution"],
  },
  {
    code: "municip5068",
    name: "Untitled Skull",
    artist: "Jean-Michel Basquiat",
    location: "New York City",
    country: "United States",
    museum: "Museum of Modern Art",
    year: 1982,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Neo-Expressionism", "Post-Modernism"],
  },
  {
    code: "municip5069",
    name: "The Dessert: Harmony in Red",
    artist: "Henri Matisse",
    location: "Saint Petersburg",
    country: "France",
    museum: "Hermitage Museum",
    year: 1908,
    latitude: 59.9343,
    longitude: 30.3351,
    attributes: ["Fauvism", "Modernism"],
  },
  {
    code: "municip5070",
    name: "Isle of the Dead",
    artist: "Arnold Böcklin",
    location: "Basel",
    country: "Switzerland",
    museum: "Kunstmuseum Basel",
    year: 1883,
    latitude: 47.5596,
    longitude: 7.5886,
    attributes: ["Symbolism", "Modernism"],
  },
  {
    code: "municip5071",
    name: "The Potato Eaters",
    artist: "Vincent van Gogh",
    location: "Amsterdam",
    country: "Netherlands",
    museum: "Van Gogh Museum",
    year: 1885,
    latitude: 52.078663,
    longitude: 4.288788,
    attributes: ["Post-Impressionism", "Modernism"],
  },
  {
    code: "municip5072",
    name: "The Arnolfini Portrait",
    artist: "Jan van Eyck",
    location: "London",
    country: "United Kingdom",
    museum: "National Gallery",
    year: 1434,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Flemish Painting", "Early Netherlandish"],
  },
  {
    code: "municip5073",
    name: "Mont Sainte-Victoire",
    artist: "Paul Cézanne",
    location: "Paris",
    country: "France",
    museum: "Musée d'Orsay",
    year: 1904,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Post-Impressionism", "Modernism"],
  },
  {
    code: "municip5074",
    name: "Conversion on the Way to Damascus",
    artist: "Caravaggio",
    location: "Rome",
    country: "Italy",
    museum: "Basilica of Santa Maria del Popolo",
    year: 1601,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["Baroque", "Italian Renaissance"],
  },
  {
    code: "municip5075",
    name: "Madonna",
    artist: "Edvard Munch",
    location: "Oslo",
    country: "Norway",
    museum: "Munch Museum",
    year: 1895,
    latitude: 59.911491,
    longitude: 10.757933,
    attributes: ["Expressionism", "Post-Impressionism"],
  },
  {
    code: "municip5076",
    name: "Liberty Leading the People",
    artist: "Eugène Delacroix",
    location: "Paris",
    country: "France",
    museum: "Musée du Louvre",
    year: 1830,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Romanticism", "French Revolution"],
  },
  {
    code: "municip5077",
    name: "Girl With a Mandolin",
    artist: "Pablo Picasso",
    location: "New York City",
    country: "Spain",
    museum: "Museum of Modern Art",
    year: 1910,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Cubism", "Modernism"],
  },
  {
    code: "municip5078",
    name: "La maja vestida",
    artist: "Francisco Goya",
    location: "Madrid",
    country: "Spain",
    museum: "Museo del Prado",
    year: 1800,
    latitude: 40.416775,
    longitude: -3.7037,
    attributes: ["Romanticism", "Spanish Golden Age"],
  },
  {
    code: "municip5079",
    name: "Nighthawks",
    artist: "Edward Hopper",
    location: "Chicago",
    country: "United States",
    museum: "Art Institute of Chicago",
    year: 1942,
    latitude: 41.8781,
    longitude: -87.6298,
    attributes: ["American Realism", "Modernism"],
  },
  {
    code: "municip5080",
    name: "A bigger splash",
    artist: "David Hockney",
    location: "London",
    country: "United Kingdom",
    museum: "Tate Britain",
    year: 1967,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Pop Art", "Modernism"],
  },
  {
    code: "municip5081",
    name: "460 Hommage au Tauchisme",
    artist: "Friedensreich Hundertwasser",
    location: "Vienna",
    country: "Austria",
    museum: "KunstHausWien",
    year: 1961,
    latitude: 48.210033,
    longitude: 16.363449,
    attributes: ["Abstract Art", "Modernism", "Expressionism"],
  },
  {
    code: "municip5082",
    name: "Number 1",
    artist: "Jackson Pollock",
    location: "New York City",
    country: "United States",
    museum: "Museum of Modern Art",
    year: 1950,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Abstract Art", "Modernism", "Expressionism"],
  },
  {
    code: "municip5083",
    name: "Three Studies for Figures at the Base of a Crucifixion",
    artist: "Francis Bacon",
    location: "London",
    country: "United Kingdom",
    museum: "Tate Britain",
    year: 1944,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Expressionism", "Surrealism", "Modernism"],
  },
  {
    code: "municip5084",
    name: "A Rake's Progress",
    artist: "William Hogarth",
    location: "London",
    country: "United Kingdom",
    museum: "Sir John Soane's Museum",
    year: 1735,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Rocooco", "British Art"],
  },
  {
    code: "municip5085",
    name: "Canyon with Crows",
    artist: "Georgia O'Keeffe",
    location: "New York City",
    country: "United States",
    museum: "Metropolitan Museum of Art",
    year: 1917,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Precisionism", "American Modernism", "Modernism"],
  },
  {
    code: "municip5086",
    name: "Martha and Mary Magdalene",
    artist: "Caravaggio",
    location: "Detroit",
    country: "Italy",
    museum: "Detroit Institute of Arts",
    year: 1598,
    latitude: 42.3314,
    longitude: -83.0458,
    attributes: [
      "Baroque",
      "Italian Renaissance",
      "Renassiance",
      "Tenebrism",
      "Early Renaissance",
      "High Renaissance",
    ],
  },
  {
    code: "municip5087",
    name: "The Maids",
    artist: "Paula Rego",
    location: "London",
    country: "United Kingdom",
    museum: "Tate Britain",
    year: 1987,
    latitude: 51.507351,
    longitude: -0.127758,
    attributes: ["Expressionism", "Surrealism", "Modernism"],
  },
  {
    code: "municip5088",
    name: "Athens is Burning! The School of Athens and the Fire in the Borgo",
    artist: "Salvador Dalí",
    location: "Figueres",
    country: "Spain",
    museum: "Dalí Theatre and Museum",
    year: 1979,
    latitude: 42.2664,
    longitude: 2.9636,
    attributes: ["Surrealism", "Modernism"],
  },
  {
    code: "municip5089",
    name: "Portrait of Henry VIII",
    artist: "Hans Holbein the Younger",
    location: "Liverpool",
    country: "United Kingdom",
    museum: "Walker Art Gallery",
    year: 1537,
    latitude: 53.4084,
    longitude: -2.9916,
    attributes: ["Northern Renaissance", "Mannerism"],
  },
  {
    code: "municip5090",
    name: "Kiss of Judas",
    artist: "Giotto",
    location: "Padua",
    country: "Italy",
    museum: "Scrovegni Chapel",
    year: 1304,
    latitude: 45.4064,
    longitude: 11.8768,
    attributes: ["Proto-Renaissance", "Italian Renaissance"],
  },
  {
    code: "municip5091",
    name: "Sunset on the Seine in Winter",
    artist: "Claude Monet",
    location: "Paris",
    country: "France",
    museum: "Musée Marmottan Monet",
    year: 1880,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Impressionism", "Pastoral"],
  },
  {
    code: "municip5092",
    name: "Pointe de la Hève at Low Tide",
    artist: "Claude Monet",
    location: "Paris",
    country: "France",
    museum: "Musée Marmottan Monet",
    year: 1865,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Impressionism", "Pastoral"],
  },
  {
    code: "municip5093",
    name: "Adam and Eve",
    artist: "Albrecht Dürer",
    location: "Madrid",
    country: "Spain",
    museum: "Museo del Prado",
    year: 1507,
    latitude: 40.416775,
    longitude: -3.7037,
    attributes: ["Northern Renaissance", "Mannerism"],
  },
  {
    code: "municip5094",
    name: "Mural",
    artist: "Jackson Pollock",
    location: "Iowa City",
    country: "United States",
    museum: "University of Iowa Museum of Art",
    year: 1943,
    latitude: 41.6611,
    longitude: -91.5302,
    attributes: ["Abstract Art", "Modernism", "Expressionism"],
  },
  {
    code: "municip5095",
    name: "Golgotha",
    artist: "Marc Chagall",
    location: "New York City",
    country: "Belarus",
    museum: "Museum of Modern Art",
    year: 1912,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Cubism", "Modernism"],
  },
  //next is dance of life by Munch
  {
    code: "municip5096",
    name: "The Dance of Life",
    artist: "Edvard Munch",
    location: "Oslo",
    country: "Norway",
    museum: "National Gallery",
    year: 1899,
    latitude: 59.911491,
    longitude: 10.757933,
    attributes: ["Expressionism", "Post-Impressionism"],
  },
  //next is the nurse by kirchner
  {
    code: "municip5097",
    name: "The Nurse",
    artist: "Ernst Ludwig Kirchner",
    location: "Basel",
    country: "Germany",
    museum: "Kunstmuseum Basel",
    year: 1913,
    latitude: 47.5596,
    longitude: 7.5886,
    attributes: ["Expressionism", "Modernism"],
  },
  // next is Moscow I by Kandinsky
  {
    code: "municip5098",
    name: "Moscow I",
    artist: "Wassily Kandinsky",
    location: "Paris",
    country: "Russia",
    museum: "Musée National d'Art Moderne",
    year: 1916,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Expressionism", "Abstract Art", "Modernism"],
  },
  //next is Angel Still Feminine by Klee
  {
    code: "municip5099",
    name: "Angel Still Feminine",
    artist: "Paul Klee",
    location: "Basel",
    country: "Switzerland",
    museum: "Kunstmuseum Basel",
    year: 1939,
    latitude: 47.5596,
    longitude: 7.5886,
    attributes: ["Expressionism", "Modernism"],
  },
  //next is carpenter by malevich
  {
    code: "municip5100",
    name: "Carpenter",
    artist: "Kazimir Malevich",
    location: "Moscow",
    country: "Russia",
    museum: "State Tretyakov Gallery",
    year: 1927,
    latitude: 55.750446,
    longitude: 37.599571,
    attributes: ["Suprematism", "Expressionism", "Modernism"],
  },
  //next is rigid and curved by kandinsky
  {
    code: "municip5101",
    name: "Rigid and Curved",
    artist: "Wassily Kandinsky",
    location: "Paris",
    country: "Russia",
    museum: "Musée National d'Art Moderne",
    year: 1935,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Expressionism", "Abstract Art", "Modernism"],
  },
  //next is "at the cafe" by Emil Nolde
  {
    code: "municip5102",
    name: "At the Cafe",
    artist: "Emil Nolde",
    location: "Berlin",
    country: "Germany",
    museum: "Neue Nationalgalerie",
    year: 1911,
    latitude: 52.5096,
    longitude: 13.3687,
    attributes: ["Expressionism", "Modernism"],
  },
  //next is "death in the sickroom" by munch
  {
    code: "municip5103",
    name: "Death in the Sickroom",
    artist: "Edvard Munch",
    location: "Oslo",
    country: "Norway",
    museum: "National Gallery",
    year: 1893,
    latitude: 59.911491,
    longitude: 10.757933,
    attributes: ["Expressionism", "Post-Impressionism"],
  },
  //next is "portrait of a seated man" by Gauguin
  {
    code: "municip5104",
    name: "Portrait of a seated man",
    artist: "Paul Gauguin",
    location: "Paris",
    country: "France",
    museum: "Musée d'Orsay",
    year: 1884,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Impressionism", "Modernism"],
  },
  //next is "seated man" by cezanne
  {
    code: "municip5105",
    name: "Seated Man",
    artist: "Paul Cézanne",
    location: "Paris",
    country: "France",
    museum: "Musée d'Orsay",
    year: 1900,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Post-Impressionism", "Impressionism", "Modernism"],
  },
  // next "antibes seen from the salis gardens" by monet
  {
    code: "municip5106",
    name: "Antibes - View of the Salis Gardens",
    artist: "Claude Monet",
    location: "Paris",
    country: "France",
    museum: "Musée d'Orsay",
    year: 1888,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Impressionism", "Modernism", "Post-Impressionism"],
  },
  //next is david and goliath by caravaggio
  {
    code: "municip5107",
    name: "David and Goliath",
    artist: "Caravaggio",
    location: "Madrid",
    country: "Italy",
    museum: "Museo del Prado",
    year: 1599,
    latitude: 40.416775,
    longitude: -3.7037,
    attributes: ["Baroque", "Tenebrism", "Renaissance", "Italian Renaissance"],
  },
  //next is office in a small city by hopper
  {
    code: "municip5108",
    name: "Office in a Small City",
    artist: "Edward Hopper",
    location: "New York City",
    country: "United States",
    museum: "Metropolitan Museum of Art",
    year: 1953,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["New Realism", "American Realism", "Modernism"],
  },
  // next is Mary Magdalen in Ecstasy by Caravaggio
  {
    code: "municip5109",
    name: "Mary Magdalen in Ecstasy",
    artist: "Caravaggio",
    location: "Rome",
    country: "Italy",
    museum: "Galleria Doria Pamphilj",
    year: 1606,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["Baroque", "Tenebrism", "Italian Renaissance"],
  },
  //next is Untitled by Mark Rothko
  {
    code: "municip5110",
    name: "Untitled",
    artist: "Mark Rothko",
    location: "New York City",
    country: "United States",
    museum: "Metropolitan Museum of Art",
    year: 1947,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Abstract Art", "Expressionism", "Modernism"],
  },
  //next is young woman with a water pitcher by vermeer
  {
    code: "municip5111",
    name: "Young Woman with a Water Pitcher",
    artist: "Johannes Vermeer",
    location: "New York City",
    country: "Netherlands",
    museum: "Metropolitan Museum of Art",
    year: 1662,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Baroque", "Dutch Golden Age"],
  },
  //next is the ninth wave by Aivazovsky
  {
    code: "municip5112",
    name: "The Ninth Wave",
    artist: "Ivan Aivazovsky",
    location: "Moscow",
    country: "Russia",
    museum: "State Tretyakov Gallery",
    year: 1850,
    latitude: 55.750446,
    longitude: 37.599571,
    attributes: ["Romanticism", "Realism"],
  },
  //next is barge haulers on the volga by Repin
  {
    code: "municip5113",
    name: "Barge Haulers on the Volga",
    artist: "Ilya Repin",
    location: "Moscow",
    country: "Russia",
    museum: "State Tretyakov Gallery",
    year: 1870,
    latitude: 55.750446,
    longitude: 37.599571,
    attributes: ["Realism"],
  },
  //next is girl with peaches by serov
  {
    code: "municip5114",
    name: "Girl With Peaches",
    artist: "Valentin Serov",
    location: "Moscow",
    country: "Russia",
    museum: "State Tretyakov Gallery",
    year: 1887,
    latitude: 55.750446,
    longitude: 37.599571,
    attributes: ["Impressionism", "Realism"],
  },
  //next is flora on sand by paul klee
  {
    code: "municip5115",
    name: "Flora on Sand",
    artist: "Paul Klee",
    location: "Basel",
    country: "Switzerland",
    museum: "Kunstmuseum Basel",
    year: 1927,
    latitude: 47.5596,
    longitude: 7.5886,
    attributes: ["Abstract Art", "Expressionism", "Modernism"],
  },
  //next is Self Portrait with Bandaged Ear by Van Gogh
  {
    code: "municip5116",
    name: "Self Portrait with Bandaged Ear",
    artist: "Vincent van Gogh",
    location: "London",
    country: "Netherlands",
    museum: "Courtauld Gallery",
    year: 1889,
    latitude: 47.5596,
    longitude: 7.5886,
    attributes: ["Post-Impressionism", "Modernism"],
  },
  //next is The Apotheosis of War by Vasily Vereshchagin
  {
    code: "municip5117",
    name: "The Apotheosis of War",
    artist: "Vasily Vereshchagin",
    location: "Moscow",
    country: "Russia",
    museum: "State Tretyakov Gallery",
    year: 1871,
    latitude: 55.750446,
    longitude: 37.599571,
    attributes: ["Realism"],
  },
  //next is The Smile of the Flamboyant Wings by joan miro
  {
    code: "municip5118",
    name: "The Smile of the Flamboyant Wings",
    artist: "Joan Miró",
    location: "New York City",
    country: "Spain",
    museum: "Museum of Modern Art",
    year: 1953,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Surrealism", "Modernism"],
  },
  //next is Landscape with Girl Skipping Rope by salvador dali
  {
    code: "municip5119",
    name: "Landscape with Girl Skipping Rope",
    artist: "Salvador Dalí",
    location: "New York City",
    country: "Spain",
    museum: "Museum of Modern Art",
    year: 1936,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Surrealism", "Modernism"],
  },
  {
    code: "municip5120",
    name: "Pumpkin installation",
    artist: "Yayoi Kusama",
    location: "Tokyo",
    country: "Japan",
    museum: "Mori Art Museum",
    year: 1990,
    latitude: 35.6762,
    longitude: 139.6503,
    attributes: [
      "Conceptual Art",
      "Feminist Art",
      "Contemporary Art",
      "Modernism",
    ],
  },
  //next is mao by andy warhol
  {
    code: "municip5121",
    name: "Mao",
    artist: "Andy Warhol",
    location: "New York City",
    country: "United States",
    museum: "Museum of Modern Art",
    year: 1972,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Pop Art", "Modernism"],
  },
  //next is The Wheat Sifters by Gustave Courbet
  {
    code: "municip5122",
    name: "The Wheat Sifters",
    artist: "Gustave Courbet",
    location: "Paris",
    country: "France",
    museum: "Musée d'Orsay",
    year: 1854,
    latitude: 48.860294,
    longitude: 2.338629,
    attributes: ["Realism"],
  },
  //next is madonna litta by leonardo da vinci
  {
    code: "municip5123",
    name: "Madonna Litta",
    artist: "Leonardo da Vinci",
    location: "Saint Petersburg",
    country: "Italy",
    museum: "Hermitage Museum",
    year: 1490,
    latitude: 59.9343,
    longitude: 30.3351,
    attributes: ["High Renaissance"],
  },
  //next is The Farm by Joan Miro
  {
    code: "municip5124",
    name: "The Farm",
    artist: "Joan Miró",
    location: "Washington, D.C.",
    country: "Spain",
    museum: "National Gallery of Art",
    year: 1921,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Cubism", "Primitivism", "Surrealism", "Modernism"],
  },
  //next is paris through the window by marc chagall
  {
    code: "municip5125",
    name: "Paris Through the Window",
    artist: "Marc Chagall",
    location: "New York City",
    country: "Belarus",
    museum: "Solomon R. Guggenheim Museum",
    year: 1913,
    latitude: 40.761509,
    longitude: -73.978271,
    attributes: ["Cubism", "Modernism"],
  },
  //next is The Swan (No. 17) by Hilma af Klint
  {
    code: "municip5126",
    name: "The Swan (No. 17)",
    artist: "Hilma af Klint",
    location: "Stockholm",
    country: "Sweden",
    museum: "Moderna Museet",
    year: 1915,
    latitude: 59.3293,
    longitude: 18.0686,
    attributes: ["Abstract Art", "Modernism"],
  },
  //next is Pacific Coast Highway and Santa Monica by David Hockney
  {
    code: "municip5127",
    name: "Pacific Coast Highway and Santa Monica",
    artist: "David Hockney",
    location: "Los Angeles",
    country: "United Kingdom",
    museum: "Los Angeles County Museum of Art",
    year: 1990,
    latitude: 34.0522,
    longitude: -118.2437,
    attributes: ["Pop Art", "Modernism"],
  },
  {
    code: "municip5128",
    name: "Composition A",
    artist: "Piet Mondrian",
    location: "Rome",
    country: "Italy",
    museum: "Galleria Nazionale d'Arte Moderna",
    year: 1923,
    latitude: 41.9029,
    longitude: 12.4534,
    attributes: ["Abstract Art", "Modernism"],
  },
];

export const countriesWithImage = countries.filter((c) =>
  countryCodesWithImage.includes(c.code.toLowerCase())
);

export function getCountryName(language: string, country: Country) {
  return country.name;
}

export function getMusemName(language: string, country: Country) {
  return country.museum;
}

export function getArtistName(language: string, country: Country) {
  return country.artist;
}

export function getCityName(language: string, country: Country) {
  return country.location;
}

export function getCountryCountry(country: Country) {
  return country.country;
}

export function sanitizeCountryName(countryName: string): string {
  return countryName
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[- '()]/g, "")
    .toLowerCase();
}

export function getYear(country: Country) {
  return country.year; //type: number
}

export function getAttributes(country: Country) {
  return country.attributes; //type: string[]
}
